<template lang="html">
  <div id="home-page">


    <section class="container-fluid banner-section">
      <swiper class="swiper-desktop" :options="bannersOption">
        <swiper-slide v-for="(banner, index) in banners" :key="index">
          <img v-if="banner.device == 'pc'" :src="banner.imageUrl">
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>

      <swiper class="swiper-mobile" :options="bannersOption">
        <swiper-slide v-for="(banner, index) in banners" :key="index">
          <img v-if="banner.device == 'movil'" :src="banner.imageUrl">
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>

    <section class="container oversized-container products-section">
      <div class="row">
        <div class="col-12 col-s-title">
          <h2 class="title">PRODUCTOS POPULARES</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-12 px-0 col-products">
          <swiper class="swiper carousel-t1 ct1-white" :options="productsOption">
            <swiper-slide v-for="(product, index) in products" :key="index">
              <div class="col-12 px-0 sample-product shad-prod">
                <router-link class="outlined-box" :to="'/producto/'+$root._converToURL(product.name, product.id)">
                  <div class="box-image">
                    <div class="out-of-stock" v-if="product.stock < 1">Agotado</div>

                    <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.img+')' }">
                      <img src="public/images/shared/empty.png" >
                    </div>
                  </div>

                  <div class="box-shot-descr">
                    <h6 class="brand">{{ product.sku }}</h6>
                    <h6 class="name">{{ product.name }}</h6>
                    <!-- <h6 class="price discount"><span>$ {{ product.price }}</span></h6> -->
                    <!-- <h6 class="price">$ {{ product.price }}</h6> -->
                    <h6 class="price" v-if="!$root.logged">$ {{ product.price }}</h6>
                    <h6 class="price" v-if="$root.logged && $root.user.price == 'normal'">$ {{ product.price }}</h6>
                    <h6 class="price" v-if="$root.logged && $root.user.price == 'dm'">$ {{ product.price_dm }}</h6>
                    <h6 class="price" v-if="$root.logged && $root.user.price == 'd'">$ {{ product.price_d }}</h6>
                    <h6 class="price" v-if="$root.logged && $root.user.price == 'sd'">$ {{ product.price_sd }}</h6>
                  </div>
                  <p class="p-button">
                    <a class="btn-more">Ver más</a>
                  </p>
                </router-link>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>

    <!-- <section class="categories-section">
      <div class="container oversized-container">
        <div class="row justify-content-center">
          <div class="col-12 col-title">
            <h2>CATEGORÍAS</h2>
          </div>

          <div class="col-md-6 col-lg-4 col-xl-3 col-info" v-for="(category, chInx) in categories" :key="'hc-'+chInx">
            <router-link class="box-info" v-bind:style="{ backgroundImage: 'url('+category.img+')' }" to="/productos">
              <img class="img" :src="category.img">

              <div class="inside">
                <h5>{{ category.name }}</h5>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section> -->

    <section class="mt-4 products-section bgcolor-section" v-if="ofertas.length">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-12 col-s-title">
            <h2 class="title">OFERTA</h2>
          </div>
        </div>

        <div class="row">
          <div class="col-12 px-0 col-products">
            <swiper class="swiper carousel-t1 ct1-white" :options="productsOption">
              <swiper-slide v-for="(product, index) in ofertas" :key="index">
                <div class="col-12 px-0 sample-product shad-prod">
                  <router-link class="outlined-box" :to="'/producto/'+$root._converToURL(product.name, product.id)">
                    <div class="box-image">
                      <div class="out-of-stock" v-if="product.stock < 1">Agotado</div>

                      <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.img+')' }">
                        <img src="public/images/shared/empty.png" >
                      </div>
                    </div>

                    <div class="box-shot-descr">
                      <h6 class="brand">{{ product.sku }}</h6>
                      <h6 class="name">{{ product.name }}</h6>
                      <!-- <h6 class="price discount"><span>$ {{ product.price }}</span></h6> -->
                      <!-- <h6 class="price">$ {{ product.price }}</h6> -->
                      <h6 class="price" v-if="!$root.logged">$ {{ product.price }}</h6>
                      <h6 class="price" v-if="$root.logged && $root.user.price == 'normal'">$ {{ product.price }}</h6>
                      <h6 class="price" v-if="$root.logged && $root.user.price == 'dm'">$ {{ product.price_dm }}</h6>
                      <h6 class="price" v-if="$root.logged && $root.user.price == 'd'">$ {{ product.price_d }}</h6>
                      <h6 class="price" v-if="$root.logged && $root.user.price == 'sd'">$ {{ product.price_sd }}</h6>
                    </div>
                    <p class="p-button">
                      <a class="btn-more">Ver más</a>
                    </p>
                  </router-link>
                </div>
              </swiper-slide>

              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>
        </div>
      </div>
    </section>

    <section class="info-section">
      <div class="container oversized-container">
        <div class="row justify-content-center">
          <div class="col-lg-11">
            <div class="row justify-content-between">
              <div class="col-lg-3 col-info">
                <h5 class="title">Particular</h5>

                <div class="box-icon">
                  <img src="public/images/pages/home/icon-particular.svg">
                </div>

                <ul>
                  <li>Compras desde 1 pieza.</li>
                  <li>Somos productores, garantizamos tu entrega en tiempo y forma.</li>
                  <li>Forma de pago variadas.</li>
                  <li>Puedes bordar el logo de tu empresa.</li>
                </ul>
              </div>

              <div class="col-lg-3 col-info">
                <h5 class="title">Distribuidores</h5>

                <div class="box-icon">
                  <img src="public/images/pages/home/icon-distribuidores.svg">
                </div>

                <ul>
                  <li>Conviértete en distribuidor de la marca líder en calidad de moda corporativa.</li>
                  <li>Somos fabricantes, te damos el mejor precio.</li>
                </ul>
              </div>

              <div class="col-lg-3 col-info">
                <h5 class="title">Empresas</h5>

                <div class="box-icon">
                  <img src="public/images/pages/home/icon-empresas.svg">
                </div>

                <ul>
                  <li>A partir de 25 piezas.</li>
                  <li>Mejoramos el precio a mayor volumen.</li>
                  <li>Producimos la cantidad que necesites.</li>
                  <li>Puedes bordar el logo de tu empresa.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      banners: [],
      products: [],
      ofertas: [],
      categories: [
        { img: 'public/images/pages/home/category-1.jpg', name: 'Camisa - Caballero' },
        { img: 'public/images/pages/home/category-2.jpg', name: 'Blusa - Dama' },
        { img: 'public/images/pages/home/category-3.jpg', name: 'Pantalón - Dama' },
        { img: 'public/images/pages/home/category-4.jpg', name: 'Pantalón - Caballero' },
      ],

      /* == Carousel options == */
      bannersOption: {
        loop: true,
        speed:800,
        effect: 'fade',

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
      },

      productsOption: {
        loop: true,
        slidesPerView: 5,
        spaceBetween: 30,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1399: {
            slidesPerView: 5
          },
          1199: {
            slidesPerView: 4
          },
          991: {
            spaceBetween: 30,
            slidesPerView: 3
          },
          575: {
            spaceBetween: 20,
            slidesPerView: 2
          },
          1: {
            slidesPerView: 1
          },
        }
      },
      // == ==
    }
  },

  methods: {
    getHome: function(){
        axios.get(tools.url('/api/home')).then(({data}) =>{
            this.banners = data.banners;
            this.products = data.destacados;
            this.ofertas = data.ofertas;

            this.$gtm.trackEvent({
                'ecommerce': {
                    'promoView': {
                        'promotions': data.datalayer
                    }
                }

            });
        });
    }
  },

    mounted(){
      this.getHome();
    }

}
</script>
