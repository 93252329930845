import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
      { path: '/', component: require(page+'home.vue').default },
      { path: '/nosotros', component: require(page+'about-us/index.vue').default, meta:{title:"Nosotros", gtm: "Nosotros"}},
      { path: '/distribuidores', component: require(page+'dealers/index.vue').default, meta:{title:"Distribuidores", gtm: "Distribuidores"}},
      { path: '/preguntas-frecuentes/:table?', component: require(page+'faqs/index.vue').default, meta:{title:"Preguntas frecuentes", gtm: "Preguntas frecuentes"}},
      { path: '/aviso-de-privacidad', component: require(page+'text-page/aviso.vue').default, meta:{title:"Aviso de Privacidad", gtm: "Aviso de Privacidad"}},
	    { path: '/terminos-y-condiciones', component: require(page+'text-page/terminos.vue').default, meta:{title:"Términos y condiciones", gtm: "Términos y condiciones"}},
      { path: '/politicas-de-pago', component: require(page+'text-page/politicaspago.vue').default, meta:{title:"Politicas de pago", gtm: "Politicas de pago"}},
      { path: '/politicas-de-envio', component: require(page+'text-page/politicasenvio.vue').default, meta:{title:"Politicas de envio", gtm: "Politicas de envio"}},
      { path: '/contacto/:url?', component: require(page+'contact/index.vue').default, name: 'contacto', meta:{title:"Contacto", gtm: "Contacto"}},

      { path: '/gracias', component: require(page+'thankyou.vue').default, meta:{title:"Gracias" }},

      { path: '/blog', name: 'blogPage', component: require(page+'blog/index.vue').default, meta:{title:"Blog", gtm: "Blog"}},
      { path: '/blog/:id?', component: require(page+'blog/detail.vue').default, meta:{title:"Blog", gtm: "Blog"}},

      { path: '/productos', component: require(page+'products/results.vue').default, meta:{title:"Productos", gtm: "Productos"}},
      { path: '/productos/:type', component: require(page+'products/type.vue').default, meta:{title:"Productos", gtm: "Productos"}},
      { path: '/productos/color/:color', component: require(page+'products/color.vue').default, meta:{title:"Productos por color", gtm: "Productos por color"}},

      { path: '/producto/:id?', component: require(page+'products/description.vue').default, meta:{title:"Producto", gtm: "Producto"}},
      { path: '/categoria/:id?', component: require(page+'products/category.vue').default, meta:{title:"Categoria", gtm: "Categoria"}},
      { path: '/busqueda', component: require(page+'products/results.vue').default, meta:{title:"Busqueda", gtm: "Busqueda"}},

      { path: '/cart/:step?', name:'cartPage', component: require(page+'carrito/index.vue').default, meta:{title:"Mi carrito de compras", gtm: "Mi carrito de compras"}},
      { path: '/historial-de-compras', component: require(page+'carrito/historial.vue').default, meta:{title:"Mi historial de compras", gtm: "Mi historial de compras"}},
      // { path: '/mis-favoritos', component: require(page+'carrito/mis-favoritos.vue').default, meta:{title:"Mis favoritos"}},

      { path: '/login', component: require(page+'user-access/login.vue').default, meta:{title:"Iniciar sesión", gtm: "Iniciar sesión"}},
      { path: '/recuperar-contrasena', component: require(page+'user-access/reset-password.vue').default, meta:{title:"Recuperar contraseña", gtm: "Recuperar contraseña"}},
      { path: '/registrarse', component: require(page+'user-access/register.vue').default, meta:{title:"Registrarse", gtm: "Registrarse"}},

      { path: '/usuario', component: require(page+'usuario-cuenta/index.vue').default, meta:{ title: 'Mi datos' },
  			children: [
  			{
  				path: '/',
  				component: require(page+'usuario-cuenta/mis-datos.vue').default,
  				meta:{ title: 'Mis datos' }
  			},
  			{
  				path: 'contrasena',
  				component: require(page+'usuario-cuenta/contrasena.vue').default,
  				meta:{ title: 'Cambiar contraseña' }
  			},
            {
                path: 'pedidos',
                component: require(page+'usuario-cuenta/historial.vue').default,
                meta:{ title: 'Pedidos' }
            },
            {
                path: 'pedido/:id?',
                component: require(page+'usuario-cuenta/detail.vue').default,
                meta:{ title: 'Detalle del pedido' }
            },
            {
                path: 'facturas',
                component: require(page+'usuario-cuenta/facturas.vue').default,
                meta:{ title: 'Facturas' }
            },
            {
                path: 'factura/:id?',
                component: require(page+'usuario-cuenta/detail.vue').default,
                meta:{ title: 'Detalle de la factura' }
            },
  	  	]
  		}

	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},50);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "Airman – Moda Corporativa", filter: (title)=>{ return title+" - Airman – Moda Corporativa"; } }
);

Vue.use(VueGtm, {
    id: 'GTM-KG3CL7Z', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    // queryParams: {
    //   // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
    //   gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
    //   gtm_preview: 'env-4',
    //   gtm_cookies_win: 'x'
    // },
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    nonce: '2726c7f26c', // Will add `nonce` to the script tag
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: MyRouter, // Pass the router instance to automatically sync with router (optional)
    // ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
    trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
});

// export {routes};
export default MyRouter;
