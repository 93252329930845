<template lang="html">
  <div id="description-page">
    <section class="breadcrumb-section">
      <div class="container oversized-container">
        <span>Producto</span>
        <span class="line">/</span>
        <span>{{ product.name }}</span>
      </div>
    </section>

    <section class="container oversized-container description-section" id="product-description">
      <div class="row">

        <div class="col-lg-6 col-gallery">
          <swiper :options="galleryOptions">
            <swiper-slide v-for="(row, galIndex) in gallery" :key="galIndex">
              <div class="image-container">
                <div class="img">
                  <v-zoom :img="row"></v-zoom>
                </div>
              </div>
            </swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>

        <b-form class="col-lg-5 col-description" @submit.prevent="addCart">

          <h1 class="name">{{ product.name }}</h1>
          <small>SKU: {{ product.sku }}</small>

          <h6 class="mt-2 subtitle">Descripción</h6>
          <div class="txt-description" v-html="product.description"></div>

          <h6 class="mt-2 subtitle"  v-if="product.fabric">{{ product.fabric.name }}</h6>
          <div class="txt-description" v-if="product.fabric" v-html="product.fabric.description"></div>

          <h6 class="mt-2 mb-1 subtitle" v-if="product.tablaPrecios">Lista de precios</h6>
          <img class="img-fluid" :src="product.tablaPrecios">

          <h6 class="subtitle" v-if="product.color">Color</h6>
          <h4 class="txt-description color" v-if="product.color">{{ product.color.name }}</h4>

            <table class="table table-sm">
              <tr>
                <th>Talla</th>
                <th>Precio</th>
                <th>Cantidad</th>
              </tr>
              <tr v-for="(size, index) in product.sizes">
                <td class="td-talla">
                  <h6 class="t-size">{{ size.name }}</h6>
                  <h6 class="t-disp" v-if="size.stock">{{ size.stock }} Disponibles</h6>
                </td>
                <td>${{ size.store }}</td>
                <td class="td-quantity">
                  <b-form-input size="sm" v-model="size.quantity" type="number" min="0" :max="size.stock"></b-form-input>
                </td>
              </tr>
            </table>

          <div class="d-block my-2" >
            <b-button type="submit" class="my-2 my-xl-0 btn-submit">Agregar al carrito <i class="far fa-shopping-cart"></i></b-button>
            <router-link class="ml-xl-3 my-2 my-xl-0 btn btn-submit btn-contact" :to="{ name: 'contacto', query: { p: product.name+', SKU: '+product.sku } }">Contactar <i class="far fa-envelope"></i></router-link>
          </div>

          <hr />

        </b-form>

        <div class="col-11 mx-auto col-extrainfo">
          <div class="row row-info">
            <div class="col-lg-6 col-label">
              <h6>Genero</h6>
            </div>
            <div class="col-lg-6 col-txt" v-html="product.gender"></div>
          </div>

          <div class="row row-info" v-if="product.color">
            <div class="col-lg-6 col-label">
              <h6>Color</h6>
            </div>
            <div class="col-lg-6 col-txt">{{ product.color.name }} <i class="hex-color" v-bind:style="{ backgroundColor: product.color.hexadecimal }"></i></div>
          </div>

          <div class="row row-info" v-if="colores.length">
            <div class="col-lg-3 col-label">
              <h6>Otros colores</h6>
            </div>

            <div class="col-lg-9 col-colors">
              <router-link class="btn-color" v-for="(c, cprInx) in colores" :key="'cprInx-'+cprInx" :to="'/producto/'+$root._converToURL(c.name, c.id)">
                <div class="col box-txt-color">
                  {{ c.color }}
                </div>
                <div class="col box-hex-color">
                  <div class="hexadecimal-color" v-bind:style="{ backgroundColor: c.hexadecimal }"></div>
                </div>
              </router-link>
            </div>
          </div>

        </div>

      </div>
    </section>

    <section class="container oversized-container related-products-section" id="related-products">
      <div class="row">
        <div class="col-12 px-0 col-s-title">
          <h2 class="title">Productos relacionados</h2>
          <hr class="mt-2 mb-2" />
        </div>
      </div>

      <div class="row">
        <div class="col-12 px-0 col-products">
          <swiper class="swiper carousel-t1 ct1-white" :options="productsOption">
            <swiper-slide v-for="(product, index) in products" :key="index">
              <div class="col-12 px-0 sample-product shad-prod">
                <router-link class="outlined-box" :to="'/producto/'+$root._converToURL(product.name, product.id)">
                  <div class="box-image">
                    <div class="out-of-stock" v-if="product.stock < 1">Agotado</div>

                    <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.img+')' }">
                      <img src="public/images/shared/empty.png" >
                    </div>
                  </div>

                  <div class="box-shot-descr">
                    <h6 class="brand">{{ product.sku }}</h6>
                    <h6 class="name">{{ product.name }}</h6>
                    <!-- <h6 class="price discount"><span>$ {{ product.price }}</span></h6> -->
                    <h6 class="price">$ {{ product.price }}</h6>
                  </div>
                  <p class="p-button">
                    <a class="btn-more">Ver más</a>
                  </p>
                </router-link>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>

    </section>

    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
        <div v-html="modal.msg"></div>
        <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
            <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close()">Agregar mas productos</b-button>
            <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close(); $router.push('/cart')">Ir al carrito <i class="color-white fas fa-shopping-cart"></i></b-button>
        </div>
    </sweet-modal>

  </div>
</template>

<script>
import vZoom from 'vue-zoom';
export default {
  components: {vZoom},

  data() {
    return {
      id: null,

      product: [],
      category: [],
      products: [],
      sizes: [],
      gallery: [],
      relatedProducts: [],
      color: '',
      colores: [],

      modal:{
        msg:'',
        icon:'',
        block:false
      },


      // == Carrusel options ==
      galleryOptions: {
        loop: false,
        speed: 600,

        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false
        // }
      },

      productsOption: {
        loop: false,
        slidesPerView: 5,
        spaceBetween: 30,
        centeredSlides: true,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1399: {
            slidesPerView: 5
          },
          1199: {
            slidesPerView: 4
          },
          991: {
            spaceBetween: 30,
            slidesPerView: 3
          },
          575: {
            spaceBetween: 20,
            slidesPerView: 2
          },
          1: {
            slidesPerView: 1
          },
        }
      },
      // == ==
    }
  },

  watch: {
    "$route.params.id" : function(v){
      //this.id = this.$route.params.id;
      this.id = this.$root._getURLID(this.$route.params.id);
      this.getProduct();
    }
  },

  methods:{
    getProduct(){
      axios.get(tools.url('/api/products/' + this.id)).then((response)=>{
        this.product = [];
        this.gallery = [];
        this.product = response.data;
        this.gallery = response.data.gallery;
        this.category = response.data.category;
        this.color = response.data.color ? response.data.color.name : '';
        this.sizes = response.data.sizes;
        this.colores = response.data.colores;

        this.$gtm.trackEvent({
            'ecommerce': {
                'detail': {
                'actionField': {'list': 'Apparel Gallery'},    // 'detail' actions have an optional list property.
                'products': [{
                    'name': this.product.name,         // Name or ID is required.
                    'id': this.product.sku,
                    'brand': 'Airman',
                    'category': 'Ropa',
                    'variant': {
                        'color': this.product.color.name
                    }
                }]
                }
            }
        });

        this.getRelated(this.product.id);
      }).catch((error)=>{
        console.log(error);
      });
    },

    addCart(){
      var cart = [];
      var encontrado = false;
      var flag = false;
      var count = 0;

      if(this.sizes.length > 0){
            if(localStorage.cart){
                cart = JSON.parse(localStorage.getItem('cart'));

                for(var x = 0; x < this.sizes.length ; x++){
                    encontrado = false;

                    if(this.sizes[x].quantity > 0){

                        for(var i = 0; i < cart.length ; i++){

                            if(cart[i].id == this.id && cart[i].size == this.sizes[x].name && cart[i].color == this.color){
                                var suma = cart[i].quantity + this.sizes[x].quantity;
                                cart[i].quantity = suma <= this.sizes[x].stock ? suma : this.sizes[x].stock;
                                encontrado = true;
                                flag = true;
                                count = count + 1;
                                break;
                            }

                        }

                        if(!encontrado){
                            cart.push({ id:this.id, quantity:this.sizes[x].quantity, size: this.sizes[x].name, color: this.color});
                            flag = true;
                            count = count + 1;
                        }
                    }

                }

            }else{
                    for(var x = 0; x < this.sizes.length ; x++){
                        if(this.sizes[x].quantity > 0){
                            cart.push({ id:this.id, quantity:this.sizes[x].quantity, size: this.sizes[x].name, color: this.color });
                            flag = true;
                            count = count + 1;
                        }
                    }
            }

          localStorage.setItem("cart",JSON.stringify(cart));
          this.$root.cartCount = cart.length;

          if(flag){
              this.datalayerAddCart();
              this.modal.msg = 'Se agregado '+count+' articulos al carrito';
              this.modal.icon = 'success';
              this.$refs.modal.open();
          }
      }

    },

    datalayerAddCart(){
        this.$gtm.trackEvent({
            event: 'addToCart',
            'ecommerce': {
            'currencyCode': 'MXN',
            'add': {
                'products': [{
                    'name': this.product.name,
                    'id': this.product.sku,
                    'brand': 'Airman',
                    'variant': this.product.color.name
                }]
                }
            }
        });
    },

    getRelated(id){
      axios.get(tools.url('/api/products/related/'+id)).then((response)=>{
        this.products = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    },

  },

   mounted(){
    //this.id = this.$route.params.id;
    this.id = this.$root._getURLID(this.$route.params.id);
    this.getProduct();
  }
}
</script>
