<template lang="html">
  <div id="cart-page" class="d-flex account-page">
    <div class="container main-box">

      <div class="row">
        <div class="col-12 px-2">
          <h1 class="mb-3 page-title">Mi carrito</h1>
        </div>

        <div class="col-lg px-2 col-menu">
          <div class="white-box">
            <div class="box-menu py-2">
              <!-- <p class="item">
                <router-link class="btn-menu" to="/mis-favoritos"><i class="fas fa-heart"></i> Mis favoritos</router-link>
              </p> -->
              <p class="item">
                <router-link class="btn-menu" to="/cart"><i class="fas fa-shopping-cart"></i> Mi carrito</router-link>
              </p>
              <!-- <p class="item">
                <router-link class="btn-menu" to="/mis-favoritos"><i class="fas fa-heart"></i> Mis favoritos</router-link>
              </p> -->
              <!-- <p class="item">
                <router-link class="btn-menu" to="/historial-de-compras"><i class="fas fa-history"></i> Historial de compras</router-link>
              </p> -->
            </div>
          </div>
        </div>

        <div class="col-lg px-2 col-cart-data">

          <!-- <div class="white-box"> -->
          <b-form class="white-box" @submit.prevent="">

            <!-- Paso 1 (Lista de productos agregados a carrito) -->
            <div class="col-12 pt-3 product-list-table" v-if="$route.params.step !== 'envio' && $route.params.step !== 'pago' && $route.params.step !== 'finalizado'">
              <div class="col-12 px-0">
                <h5>Productos agregados al carrito</h5>

                <h6 class="mt-4 pb-4 f-w-400 text-warning" v-if="!form.products.length"><i class="fas fa-shopping-basket"></i> Ningun producto ha sido agregado al carrito</h6>
              </div>

              <div class="row row-products" v-for="(product, index) in form.products">
                <div class="col col-image">
                  <img :src="product.imageUrl">
                </div>

                <div class="col col-info">
                  <h5 class="txt-name">
                    <router-link target="_blank" :to="'/producto/'+product.id">{{ product.name }}</router-link>
                  </h5>

                  <div class="row mx-0 align-items-center">
                        <div class="d-flex">
                            <h6 class="txt-sm text-muted mr-2">SKU: {{ product.sku }} </h6>
                            <h6 class="txt-sm text-muted mr-2" v-if="product.color"> Color: {{ product.color }} </h6>
                            <h6 class="txt-sm text-muted" v-if="product.size"> Talla: {{ product.size }} </h6>
                        </div>
                        <!-- <div class="d-flex">
                            <div class="box-color-opt opt-sm">
                                <a class="color" title="Color x" v-bind:style="{ backgroundColor: '#e63003' }">{{ product.color }}</a>
                                <a class="color" :title="product.color">{{ product.color }}</a>
                                <a class="color" :title="product.size">{{ product.size }}</a>
                            </div>
                        </div> -->
                  </div>
                  <hr class="mt-0 mb-1" />

                  <router-link class="link-sm text-muted" target="_blank" :to="'/producto/'+product.id"><i class="far fa-tshirt"></i> Ver producto</router-link> <span class="mx-2">|</span> <a class="link-sm text-danger" @click="removeCart(index)"><i class="far fa-trash-alt"></i> Quitar</a>
                </div>

                <div class="col col-price">
                  <p>$ {{ product.price }} MXN</p>
                </div>
                <div class="col col-num">
                  <b-form-input v-model="product.quantity" type="number" min="1" :max="product.stock" placeholder="0" @change="updateCart()"/>
                </div>
              </div>
            </div>
            <!-- paso 1, Fin -->

            <!-- Paso 2 (Envio, Datos del usuario y datos de envío) -->
            <div class="col-12 py-4 px-4 user-data" v-if="$route.params.step == 'envio'">
              <h6>Datos de personales</h6>
              <hr class="c-hr" />

              <div class="custom-input _p">
                <label class="col-form-label pt-0">Nombre:</label>
                <p class="txt-simple" v-if="$root.logged">{{ form.user.name }}</p>
                <b-form-input id="user-name" v-else type="text" v-model="form.user.name" size="sm" required placeholder="Nombre" required/>
              </div>

              <div class="custom-input _p">
                <label class="col-form-label pt-0">Apellidos:</label>
                <p class="txt-simple" v-if="$root.logged" v-text="form.user.lastname"></p>
                <b-form-input id="user-lastname" v-else type="text" v-model="form.user.lastname" size="sm" required placeholder="Apellidos" required/>
              </div>

              <div class="custom-input _p">
                <label class="col-form-label pt-0">Correo electrónico:</label>
                <p class="txt-simple" v-if="$root.logged" v-text="form.user.email"></p>
                <b-form-input id="user-email" v-else type="text" v-model="form.user.email" size="sm" required placeholder="Correo electrónico" required/>
              </div>

              <div class="custom-input _p">
                <label class="col-form-label pt-0">Teléfono:</label>
                <b-form-input id="user-phone" type="text" v-model="form.user.phone" size="sm" required placeholder="Teléfono" maxlength="10" required/>
              </div>

              <h6 class="mt-5">Información de envío</h6>
              <hr class="c-hr" />

              <div class="row mx-0 no-gutters">
                <div class="col-md-12">
                  <b-form-group class="custom-input" label="Tipo de entrega:" label-for="i-entrega">
                    <v-select v-model="form.shippingMethod" :options="['Enviar a domicilio', 'Recoger en tienda']" placeholder="Tipo de entrega"/>
                  </b-form-group>
                </div>
              </div>

                <div v-if="form.shippingMethod == 'Enviar a domicilio'">
                    <div class="row mx-0 no-gutters">
                        <div class="col-md-7 col-lg-6">
                        <b-form-group class="custom-input" label="Calle:" label-for="i-calle">
                            <b-form-input id="i-calle" type="text" v-model="form.user.street" size="sm" placeholder="Calle donde vive" required/>
                        </b-form-group>
                        </div>

                        <div class="col-md pl-md-3">
                        <b-form-group class="custom-input" label="Numero exterior:" label-for="i-num">
                            <b-form-input id="i-num" type="text" v-model="form.user.num_ext" size="sm" required/>
                        </b-form-group>
                        </div>

                        <div class="col-md pl-md-3">
                        <b-form-group class="custom-input" label="Numero interior:" label-for="i-num">
                            <b-form-input id="i-num" type="text" v-model="form.user.num_int" size="sm"/>
                        </b-form-group>
                        </div>
                    </div>

                    <div class="row mx-0 no-gutters">
                        <div class="col-md-8">
                        <b-form-group class="custom-input" label="Colonia:" label-for="i-colonia">
                            <b-form-input id="i-colonia" type="text" v-model="form.user.neighborhood" size="sm" required placeholder="Escriba su colonia" />
                        </b-form-group>
                        </div>

                        <div class="col-md pl-md-3">
                        <b-form-group class="custom-input" label="Código postal:" label-for="i-cp">
                            <b-form-input id="i-cp" type="text" v-model="form.user.zipcode" size="sm" required placeholder="Escriba su código postal" />
                        </b-form-group>
                        </div>
                    </div>


                    <div class="row mx-0 no-gutters">
                        <div class="col-md-6 pr-md-3">
                        <b-form-group class="custom-input" label="Estado:" label-for="i-estado">
                            <v-select v-model="form.user.state_id" :options="states" label="name" index="id" @change="getTowns"/>
                        </b-form-group>
                        </div>

                        <div class="col-md-6">
                        <b-form-group class="custom-input" label="Ciudad:" label-for="i-ciudad">
                            <v-select v-model="form.user.town_id" :options="towns" label="name" index="id"/>
                        </b-form-group>
                        </div>
                    </div>
                </div>
                <div v-if="form.shippingMethod == 'Recoger en tienda'">
                    <p>Calle Gral. Eulogio Parra 1630 Santa Teresita, 44600 Guadalajara, Jalisco.</p>
                </div>
            </div>
            <!-- Paso 2, fin  -->

            <!-- Paso 3 (pago) -->
            <div class="col-12 py-4 px-4 user-data" v-if="$route.params.step == 'pago'">
              <h6>Información de pago</h6>
              <hr class="c-hr" />

              <div class="custom-input _p">
                <label class="col-form-label pt-0">Seleccione una forma de pago:</label>
              </div>

              <div class="row mb-4 payment-options-container">
                <!-- <div class="col col-box" @click="changePaymentMe('tarjetas')">
                  <div class="box" v-bind:class="{ active: form.tipoPago == 'tarjetas' }">
                    <h6>Tarjeta de crédito o débito</h6>
                    <p class="icons">
                      <i class="fab fa-cc-visa"></i>
                      <i class="fab fa-cc-mastercard"></i>
                      <i class="fab fa-cc-amex"></i>
                    </p>
                  </div>
                </div> -->

                <div class="col col-box" @click="changePaymentMe('transferencia')">
                  <div class="box" v-bind:class="{ active: form.tipoPago == 'transferencia' }">
                    <h6>Deposito o transferencia<br />bancaria</h6>
                    <p class="icons">
                      <i class="fas fa-money-check-alt"></i>
                      <i class="fas fa-money-bill"></i>
                    </p>
                  </div>
                </div>

                <!-- <div class="col col-box" @click="changePaymentMe('paypal')">
                  <div class="box" v-bind:class="{ active: form.tipoPago == 'paypal' }">
                    <h6>Pago a traves de Paypal</h6>
                    <p class="icons">
                      <i class="fab fa-paypal"></i>
                    </p>
                  </div>
                </div> -->
              </div>

              <!-- Pago 1, con tarjetas -->
              <div class="row mx-0 no-gutters" v-if="form.tipoPago == 'tarjetas'">

              </div>
              <!--  -->

              <!-- Pago 2, con transferencia bancaria -->
              <div class="col-12 text-center" v-if="form.tipoPago == 'transferencia'">

                <img src="public/images/bbva.png" width="100">
                <p><strong>Beneficiario:</strong> TOP IMPORTACIONES S.A. DE C.V.</p>
                <p><strong>No de cuenta:</strong> 0115730082</p>
                <p><strong>CLABE:</strong> 012320001157300825</p>

                <hr />
                <p>
                  Favor de enviarnos el comprobante de pago al siguiente correo: <br />
                  <strong>pago@airman.com.mx</strong>
                </p>
              </div>
              <!--  -->

              <!-- Pago 3, con paypal -->
              <div class="col-12 text-center" v-if="form.tipoPago == 'paypal'">
                <p>Depósito por medio de paypal.</p>

                <hr />
                <p>
                  Favor de enviarnos el comprobante de pago al siguiente correo: <br />
                  <strong>pago@airman.com.mx</strong>
                </p>
              </div>
              <!--  -->
            </div>
            <!-- Paso 3, fin -->

            <!-- Paso 4 (pago) -->
            <div class="col-12 py-5 px-4 col-finish" v-if="$route.params.step == 'finalizado'">
              <p class="mb-3">
                <!-- <i class="far fa-check-circle icon-check"></i> -->
                <!-- <i class="fal fa-check-circle icon-check"></i> -->
                <success-component></success-component>
              </p>
              <h4 class="mb-3 f-w-600">¡Tu orden esta siendo procesada!</h4>

              <h5>Número de orden es: <strong class="txt-orange">{{ modal.orderId }}</strong></h5>
              <h5>En cuanto se convierta en pedido recibiras un correo electronico con la confirmacion del mismo y un Numero de pedido.</h5>

              <p>
                <router-link to="/"><strong><i class="fas fa-home"></i> Ir al Inicio</strong></router-link>
              </p>
            </div>
            <!--  -->
          </b-form>

          <div class="row mx-0 py-3 px-lg-0 no-gutters row-total" v-if="$route.params.step != 'finalizado'">
            <div class="col-lg-6">
              <router-link class="btn btn-outline-danger btn-sm btn-action" :to="'/cart/' + form.prev" v-if="form.prev != null">Anterior</router-link>
              <router-link class="btn btn-outline-danger btn-sm btn-action" :to="'/cart/' + form.next" v-if="form.next">Siguiente</router-link>
              <button class="btn btn-danger btn-sm btn-action" v-if="form.paying && form.tipoPago == 'transferencia'" @click="createOrder()" :disabled="order_id">Confirmar pedido</button>
            </div>

            <div class="col-lg-6 text-right">
              <h6><span>Subtotal: </span> ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(subtotal) }}</h6>
              <h6><span>Envío: </span> ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(form.envio) }}</h6>
              <h6>Se enviara en {{ form.boxes }} cajas y llegara en 3 a 4 días hábiles</h6>
              <h5><span>Total: </span> ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(total) }}</h5>
            </div>
          </div>
        </div>
      </div>

      <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        <div v-html="modal.msg"></div>
        <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
          <b-button class="btn btn-success" slot="button" v-on:click.prevent="$refs.modal.close(); $router.push('/')">Regresar a Home <i class="color-white fas fa-home"></i></b-button>
        </div>
      </sweet-modal>

    </div>
  </div>
</template>

<script>
import successComponent from '../shared/success-component.vue';
export default {
  data(){
    return{
      form: {
        products: [],

        user: {
          "email": '',
          "name": '',
          "lastname": '',
          "phone": '',
          "street": '',
          "num_ext": '',
          "num_int": '',
          "neighborhood":'',
          "zipcode": null,
          "state_id": null,
          "town_id": null
        },

        prev: '',
        next: '',
        paying: false,

        tipoPago: '',
        subtotal: 0,
        total: 0,
        envio: 219.24,
        shippingMethod: 'Enviar a domicilio',
        boxes: 1
      },

      modal:{
        msg:'',
        icon:'',
        block:false,
        orderId: null,
      },

      states: [],
      towns: [],
      order_id: null,
      envio: 219.24,
    }
  },

  computed: {
    subtotal() {
     var subtotal = this.form.products.reduce(function (subtotal, item) {
        return subtotal + (item.price * item.quantity)
      },0);

      return this.form.subtotal = subtotal;
    },

    total() {
        var subtotal = this.form.products.reduce(function (sum, item) {
            return sum +  (item.price * item.quantity)
        },0);

        if(this.form.shippingMethod == 'Recoger en tienda'){
          this.form.envio = 0;
          var grandTotal = parseFloat(subtotal);
        }else{
          this.form.envio = this.envio;
          var grandTotal = parseFloat(subtotal) + parseFloat(this.form.envio);
        }

        return this.form.total = grandTotal;
    }
  },

  methods: {
    changePaymentMe(tipo){
      this.form.tipoPago = tipo;
    },

    getSteps(){
      var step = this.$route.params.step;
      this.form.paying = false;

      if(step == 'pago'){ // Paso 3
        this.form.prev = 'envio';
        this.form.next = null;
        this.form.paying = true;
      }
      else if(step == 'envio'){ // Paso 2
        this.form.prev = '';
        this.form.next = 'pago';
      }
      else{ // Paso 1
        this.form.prev = null;

        if(this.form.products.length){
          this.form.next = 'envio';
        }
      }
    },

    getStates(){
      axios.get(tools.url('/api/states')).then((response)=>{
        this.states = response.data;
      }).catch((error)=>{
         console.log(error);
      });
    },

    getTowns(){
      if(this.form.user.state_id){
        axios.get(tools.url('/api/towns/' + this.form.user.state_id)).then((response)=>{
          this.towns = response.data;
        }).catch((error)=>{
          console.log(error);
        });
      }
    },

    getInfo(){
      this.form.user = {
        //Datos personales
        id: this.$root.user.id,
        name: this.$root.user.name,
        lastname: this.$root.user.lastname,
        email: this.$root.user.email,
        phone: this.$root.user.phone,
        street: this.$root.user.address.street,
        num_ext: this.$root.user.address.num_ext,
        num_int: this.$root.user.address.num_int,
        neighborhood: this.$root.user.address.neighborhood,
        zipcode: this.$root.user.address.zipcode,
        state_id: this.$root.user.address.state_id,
        town_id: this.$root.user.address.town_id,
      };

      this.getTowns();
    },

    getCart: function(){
      var storage = localStorage.getItem('cart');
      if(storage != null){
        var products = JSON.parse(storage);
        var userId = this.$root.logged ? this.$root.user.id : '';
        axios.post(tools.url('/api/cart'),{ products:products, userId:userId }).then((response)=>{
          this.form.products = response.data.cart;
          this.form.envio = response.data.envio;
          this.envio =  this.form.envio;
          this.form.boxes =  response.data.boxes;
          this.getSteps();

        }).catch((error)=>{
          console.log(error);
          this.getSteps();
        });
      }
    },

    removeCart: function(index) {
        this.datalayerRemoveCart(index);
        this.$delete(this.form.products, index);
        this.updateCart();
    },

    datalayerRemoveCart(index){
        this.$gtm.trackEvent({
            event: 'removeFromCart',
            'ecommerce': {
            'remove': {                               // 'remove' actionFieldObject measures.
                'products': [{                          //  removing a product to a shopping cart.
                    'name': this.form.products[index].name,
                    'id': this.form.products[index].sku,
                    'price': this.form.products[index].price,
                    'brand': 'Airman',
                    'variant': this.form.products[index].color,
                    'quantity': this.form.products[index].quantity
                }]
            }
        }

        });
    },

    updateCart(){
        var cart = [];
        this.form.products.forEach(function(val, index, array){
          var info = { id:val.id, quantity: val.quantity, size: val.size, color: val.color };
          cart.push(info);
        });
        localStorage.setItem("cart",JSON.stringify(cart));
        this.$root.cartCount = cart.length;
        this.getCart();
    },

    createOrder: function(){
        this.modal.block = true;
        this.modal.icon = '';
        this.modal.msg = 'Procesando, por favor espere...';
        this.$refs.modal.open();

        axios.post(tools.url('/api/order/store'),{ order:this.form, products:this.form.products, user:this.form.user, userLogin:this.$root.logged }).then((response)=>{
            if(response.data.type === "success"){
                this.$refs.modal.close();
                this.order_id = response.data.order_id;
                localStorage.removeItem('cart');
                this.$root.cartCount = 0;
                //mensaje
                // this.modal.icon = "success";
                this.modal.orderId = response.data.order_id;
                // this.modal.msg = "TU PEDIDO HA SIDO EXITOSO!<br/> Número de pedido: "+response.data.order_id+". Por favor de enviar su comprobante de pago";
                this.modal.msg = null;
                this.$router.push({ path: '/cart/finalizado' })
                // this.modal.block = true;
                // this.$refs.modal.open();

            }else{
                this.$refs.modal.close();
                alert(response.data.error);
            }
        }).catch((error)=>{
            console.log(error);
            this.modal.icon="error";
            this.modal.msg = "Fallo al realizar el pedido., Verifique su internet y recargue la página";
            this.modal.block = false;
            this.$refs.modal.open();
        });

    },
  },

  beforeMount(){
    this.getSteps();
    this.getStates();

    if(this.$root.logged){
      this.getInfo();
    }
  },

  watch: {
    '$route.params.step': function () {
      this.getSteps();
    },

    "$root.logged": function(v) {
      if(v == true){
        this.getInfo();
        this.getCart();
      }
    },
  },

  mounted(){
    this.getCart();
  },

  components: {
    successComponent
  },
}
</script>
