<template lang="html">
    <div class="placed-backg faqs-page" id="page-info-t1">
        <!-- <div class="bg-image"></div> -->
        <section class="container oversized-container content-section">
            <div class="row align-items-center justify-content-center" v-bind:class="{ 'tabla-page' : $route.params.table == 'tabla' }">
                <div class="col-lg-10 col-info" id="faqs-box">
                    <div class="d-block text-center">
                      <success-component></success-component>
                    </div>

                    <h1 class="mb-4 page-title">¡Gracias por descargar nuestro Catálogo!</h1>

                    <p class="text-center">
                      <router-link class="txt-orange" style="font-size: 1.3rem;" to="/"><i class="fas fa-home"></i> Ir al inicio</router-link>
                    </p>
                </div>
            </div>

        </section>
    </div>
</template>

<script>
import successComponent from './shared/success-component.vue';
export default {
  data(){
    return{
      content: '',
    }
  },

  methods: {
  },

  beforeMount(){
  },

  components: {
    successComponent
  },
}
</script>
