<template lang="html">
  <b-form @submit="onSubmit()">
    <h6>Contraseña</h6>
    <hr class="c-hr" />

    <b-form-group class="custom-input form-g-pss"
      label="Escriba su actual contraseña:"
      label-for="i-password">
      <b-form-input id="i-password" type="password"
        v-model="form.password" size="sm"
        required placeholder="********" />
      <i class="fas icon-eye" v-bind:class="{ 'fa-eye' : showpass, 'fa-eye-slash' : !showpass }" @click="showpass = !showpass"></i>
    </b-form-group>

    <b-form-group class="custom-input form-g-pss"
      label="Escriba su nueva contraseña:"
      label-for="i-npassword">
      <b-form-input id="i-npassword" type="password"
        v-model="form.npassword" size="sm"
        required placeholder="********" />
      <i class="fas icon-eye" v-bind:class="{ 'fa-eye' : showpassTwo, 'fa-eye-slash' : !showpassTwo }" @click="showpassTwo = !showpassTwo"></i>
    </b-form-group>

    <b-form-group class="custom-input form-g-pss"
      label="Vuelva a escribir su nueva contraseña:"
      label-for="i-conpassword">
      <b-form-input id="i-conpassword" type="password"
        v-model="form.conpassword" size="sm"
        required placeholder="********" />
      <i class="fas icon-eye" v-bind:class="{ 'fa-eye' : showpassThree, 'fa-eye-slash' : !showpassThree }" @click="showpassThree = !showpassThree"></i>
    </b-form-group>

    <b-button type="submit" class="btn-red btn-form" variant="primary">Cambiar contraseña</b-button>

  </b-form>
</template>

<script>
export default {
  data(){
    return{
      showpass: false,
      showpassTwo: false,
      showpassThree: false,

      form: {
        password: '',
        npassword: '',
        conpassword: ''
      }
    }
  },

  watch:{
    'showpass':function (val) {
      if (val == true) {
          $("#i-password").prop('type','text');
      }
      else if (val == false) {
        $("#i-password").prop('type','password');
      }
    },

    'showpassTwo':function (val) {
      if (val == true) {
          $("#i-npassword").prop('type','text');
      }
      else if (val == false) {
        $("#i-npassword").prop('type','password');
      }
    },

    'showpassThree':function (val) {
      if (val == true) {
          $("#i-conpassword").prop('type','text');
      }
      else if (val == false) {
        $("#i-conpassword").prop('type','password');
      }
    }
  },

  methods: {
    onSubmit(){
      console.log('Mi contraseña...');
    }
  }
}
</script>
