<template lang="html">
  <div class="placed-backg dealers-page" id="page-info-t1">
    <div class="bg-image"></div>

    <section class="container oversized-container content-section">
      <div class="row align-items-center justify-content-center">

        <div class="col-lg-10 col-info">
          <h1 class="mb-4 page-title">Distribuidores</h1>

          <h5 class="subtitle-sm">¿Tus clientes te piden prendas de mejor calidad?</h5>
          <p>
            Airman Moda Corporativa te ofrece prendas con calidad premium GARANTIZADAS!!
          </p>

          <h5 class="mt-2 subtitle-sm">¿Tu proveedor no te respalda?</h5>
          <p>
            Airman te ofrece grandes existencias para entrega inmediata, apoyo para arrancar, marketing que provoca mayor reconocimiento de la marca como la de mayor calidad en México y muchas cosas más!
          </p>

          <h5 class="mt-2 subtitle-sm">¿Tu proveedor de uniformes te entrega tarde e incompleto?</h5>
          <p>
            Conviértete en un Distribuidor Airman y recibe tus pedidos a tiempo y completos
          </p>

          <h5 class="mt-2 subtitle-sm">¿Hacen envíos a todo México?</h5>
          <p>
            SI! Enviamos tus pedidos por paquetería con entrega a domicilio SIN COSTO!
          </p>

          <h5 class="mt-2 subtitle-sm">¿Hacen envíos a todo México?</h5>
          <p>
            El perfil de un distribuidor Airman es el de un negocio en funciones con capacidad y experiencia en la venta de uniformes y/o el conocimiento a través de relaciones comerciales del mercado local de empresas que uniformen a su personal. Pueden ser fabricantes o distribuidores de cualquier tipo de uniformes, bordadores o impresores, comerciantes de equipo de seguridad industrial o de artículos promocionales, agencias de publicidad, etc.
          </p>
          <p>
            Es deseable, aunque no indispensable, que conozcan sobre uniformes, bordados, impresiones y estampados, tener uno o varios puntos de venta, una fuerza de ventas, una página web y/o presencia en redes sociales.<br />
            Lo que si es imprescindible tener es, una cartera de clientes potenciales, experiencia en ventas corporativas y una situación fiscal y financiera estable.
          </p>
          <p>
            Lo que les pedimos para iniciar es que adquieran muestrario y tallero de todos nuestros productos, que si tienen puntos de venta o página web, nos dediquen un espacio para tener presencia, que cuando un prospecto les pida algún producto que exista en nuestro catálogo lo ofrezcan aunque de momento ya adquieran ese producto de otro fabricante.
          </p>
        </div>
      </div>
    </section>

    <section class="info-color-section">
      <div class="container med-container">
        <div class="row justify-content-center">
          <div class="col-12 col-s-title">
            <h2 class="title">¿Porqué ser un distribuidor Airman?</h2>
          </div>

          <div class="col-lg-11">
            <div class="row align-items-center justify-content-center">
              <div class="col-md-6 col-lg-4 col-info">
                <h5 class="title">Aprovecha el crecimiento y liderazgo de la marca de moda corporativa en México</h5>
              </div>

              <div class="col-md-6 col-lg-4 col-info">
                <h5 class="title">Disponibilidad de más de medio millón de prendas en inventario de calidad garantizadas.</h5>
              </div>

              <div class="col-md-6 col-lg-4 col-info">
                <h5 class="title">Entregas completas y a tiempo.</h5>
              </div>

              <div class="col-md-6 col-lg-4 col-info">
                <h5 class="title">Apoyo para la adquisición de muestrarios y talleres con costo compartido.</h5>
              </div>

              <div class="col-md-6 col-lg-4 col-info">
                <h5 class="title">Precios competitivos de acuerdo a tus volúmenes de compra y resurtidos al mismo costo.</h5>
              </div>

              <div class="col-md-6 col-lg-4 col-info">
                <h5 class="title">Fabricaciones especiales de cualquier prenda que no tengamos en existencia.</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container oversized-container content-section">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-10 mt-3 col-info">
          <h2 class="mb-2 page-title">Solicita una lista de precios para distribuidor</h2>

          <div class="row justify-content-center">
            <div class="col-lg-6">
              <b-form @submit="onSubmit">
                <b-form-group>
                  <b-form-input
                    v-model="form.name"
                    type="text"
                    required
                    placeholder="Nombre"
                  ></b-form-input>
                </b-form-group>

                <b-form-group>
                  <b-form-input
                    v-model="form.email"
                    type="email"
                    required
                    placeholder="Correo electrónico"
                  ></b-form-input>
                </b-form-group>

                <b-form-group>
                  <b-form-input
                    v-model="form.phone"
                    type="text"
                    required
                    placeholder="Teléfono"
                  ></b-form-input>
                </b-form-group>

                <b-form-group>
                  <b-form-textarea
                    v-model="form.msg"
                    placeholder="Mensaje"
                    required
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>

                <p class="text-center">
                  <b-button type="submit" class="btn-send">Enviar</b-button>
                </p>
              </b-form>
            </div>
          </div>
        </div>

      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        name: null,
        email: null,
        phone: null,
        msg: null
      },
      redes: [],
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      })
    },

    getRedes: function(){
      axios.get(tools.url('/redes')).then((response)=>{
        this.redes = response.data;;
      }).catch((error)=>{
        console.log(error);
      })
    },

    onSubmit(evt) {
      evt.preventDefault();
      //var data=tools.params(form, this.form);
      axios.post(tools.url("/contacto"), this.form).then((response)=>{
          this.makeToast('default', response.data.response, 'Mensaje enviado');
          this.form = {
            name: null,
            email: null,
            msg: null,
            check: null
          };
        }).catch( (error)=> {
        // console.log(error.response);
        let errors = error.response.data;
        // console.log(errors);
        this.makeToast('danger', error.response.data.name, 'Error');
        this.makeToast('danger', error.response.data.email, 'Error');
        this.makeToast('danger', error.response.data.phone, 'Error');
        this.makeToast('danger', error.response.data.msg, 'Error');
      });
    }
  }
}
</script>
