<template lang="html">
  <footer id="footer">
    <section class="newsletter-section">
      <h2>Newsletter</h2>

      <b-form @submit.prevent="onSubmit">
        <b-form-group>
          <b-form-input
            v-model="form.email"
            type="email"
            required
            placeholder="Correo electrónico"
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" class="btn-send">Enviar</b-button>
      </b-form>
    </section>

    <section class="container oversized-container footer-section">
      <div class="row">
        <div class="col-6 col-lg col-footer">
          <h6>Dirección</h6>
          <p v-html="info.address"></p>
        </div>

        <div class="col-6 col-lg col-footer">
          <h6>Teléfonos</h6>
          <p>
            <i class="fas fa-phone-alt mr-1 icon-1"></i> {{ info.telefono }}
          </p>
        </div>

        <div class="col-lg col-logo">
          <router-link to="/">
            <img src="public/images/logo-blue-orange.svg">
          </router-link>
        </div>

        <div class="col-6 col-lg col-footer col-r">
          <h6>Correos Electrónicos</h6>

          <p>
            <a class="trans-250 btn-box" :href="'mailto:'+info.email">{{ info.email }}</a>
          </p>
        </div>

        <div class="col-6 col-lg col-footer col-r">
          <h6>Ayuda</h6>

          <p>
            <router-link to="/aviso-de-privacidad">Aviso de Privacidad</router-link>
          </p>
          <p>
            <router-link to="/terminos-y-condiciones">Términos y Condiciones</router-link>
          </p>
          <p>
            <router-link to="/politicas-de-pago">Politicas de Pago</router-link>
          </p>
          <p>
            <router-link to="/politicas-de-envio">politicas de Envío</router-link>
          </p>

          <!-- <p>
            <router-link to="">Preguntas Frecuentes</router-link>
          </p> -->
        </div>

        <div class="col-12 text-center col-footer col-copyright">
          <p class="f-w-600">
            Copyright {{ currentYear }}. TOP IMPORTACIONES SA DE CV.<br />Todos los Derechos Reservados
          </p>
        </div>

        <div class="col-12 col-networks">
          <div class="d-block">
            <a class="trans-250 link" :href="info.facebook"><i class="fab fa-facebook-square"></i></a>
            <a class="trans-250 link" :href="info.instagram"><i class="fab fa-instagram"></i></a>
            <!-- <a class="trans-250 link" href="#"><i class="fab fa-pinterest"></i></a> -->
          </div>
        </div>

        <div class="col-12 col-logos">
          <hr />
          <div class="row">
            <div class="col-6 col-lg-3 col-afil">
              <img src="public/images/shared/afiliacion-1.jpg">
            </div>

            <div class="col-6 col-lg-3 col-afil">
              <img src="public/images/shared/afiliacion-2.jpg">
            </div>

            <div class="col-6 col-lg-3 col-afil">
              <img src="public/images/shared/afiliacion-3.jpg">
            </div>

            <div class="col-6 col-lg-3 col-afil">
              <img src="public/images/shared/afiliacion-4.jpg">
            </div>
          </div>
        </div>
      </div>
    </section>

  </footer>
</template>

<script>
export default {
  data(){
    return{
      currentYear: 0,

      form: {
        email: null
      },

      info: {
        telefono: '',
        email: '',
        facebook: '#',
        instagram: '#',
        youtube: '',
        address: 'Av. Diamante 2680.<br />Col. Bosques de la Victoria<br />Guadalajara, Jal.'
      }
    }
  },

  methods:{

    makeToast(variant = null, msg, title) {
        this.$bvToast.toast(msg, {
          title: title,
          variant: variant,
          solid: true,
          toaster: 'b-toaster-top-right',
          appendToast: true
        });
    },

    onSubmit(evt) {
      evt.preventDefault();
      var r = confirm("¿Deseas regístrate en newsletter? ");
      if (r == true) {
        axios.post(tools.url("/api/newsletter"), this.form).then((response)=>{
          this.makeToast('default', response.data.response, 'Correo registrado');
          this.form = {
            name: null,
            email: null,
            msg: null,
            check: null
          };
        }).catch( (error)=> {
          // console.log(error.response);
          let errors = error.response.data;
          // console.log(errors);
          this.makeToast('danger', error.response.data.name, 'Error');
          this.makeToast('danger', error.response.data.email, 'Error');
          this.makeToast('danger', error.response.data.phone, 'Error');
          this.makeToast('danger', error.response.data.msg, 'Error');
        });
      }
    },

    getInfo: function(){
      axios.get(tools.url('/api/info')).then((response)=>{
        this.info = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    }

  },

  beforeMount(){
    this.getInfo();
    this.currentYear = new Date().getFullYear();
  }
}
</script>
