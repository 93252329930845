<template lang="html">
  <div class="placed-backg contact-page-" id="page-info-t1">
    <div class="bg-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/contact/bg.jpg)' }"></div>

    <section class="container oversized-container content-section">
      <div class="row flex-center-xy" v-if="$route.params.url == 'gracias'">
        <div class="col-lg-5 py-4 col-thanks-contact">
          <i class="fad fa-mailbox mb-1 icon"></i>
          <h5>
            Gracias por enviar su mensaje.<br />
            Nos comunicaremos con usted a la brevedad.
          </h5>
        </div>
      </div>

      <div class="row flex-center-xy" v-else>
        <div class="col-lg-5 col-info">
          <h1 class="mb-2 page-title">Contacto</h1>

          <p>
            Nos gustaría llevarte nuestro muestrario, regalarte un catálogo y platicarte sobre nuestros atractivos precios de mayoreo en persona.
          </p>
          <p class="mt-2">
            Escríbenos o llámanos para programar una cita o llamada.
          </p>
          <p class="mt-2 f-w-600">
            Por motivo de la emergencia sanitaria del covid-19 tenemos horarios restringidos, favor de consultar previo a su visita.
          </p>

          <hr />
          <h5 class="subtitle-sm">Airman Guadalajara</h5>
          <p>
            <strong class="f-w-600">Teléfono:</strong> {{ info.telefono }}  <br />
            <!-- <strong class="f-w-600">WhatsApp:</strong> (33) 2951 5293 -->
            <strong class="f-w-600">Correo:</strong> {{ info.email }} <br />
          </p>

          <br />
          <h5 class="subtitle-sm">Dirección</h5>
          <p v-html="info.address"></p>

          <br />
          <h5 class="subtitle-sm">Horario</h5>
          <p>
            <strong class="f-w-600">Lunes a viernes:</strong> 9:00 a 18:00<br />
            <strong class="f-w-600">Sabado:</strong> 10:00 a 14:00
          </p>

          <br />
          <p class="f-w-600">
            De momento solo atendemos en nuestra sala de exhibición con cita confirmada
          </p>
        </div>

        <div class="col-lg-5 col-xl-4 col-info">
          <b-form @submit.prevent="onSubmit">
            <b-form-group>
              <b-form-input
                v-model="form.name"
                type="text"
                required
                placeholder="Nombre"
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input
                v-model="form.email"
                type="email"
                required
                placeholder="Correo electrónico"
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input
                v-model="form.phone"
                type="text"
                required
                placeholder="Teléfono"
                maxlength="10"
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-textarea
                v-model="form.msg"
                placeholder="Mensaje"
                required
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <p class="text-center">
              <b-button type="submit" class="btn-send">Enviar</b-button>
            </p>
          </b-form>

        </div>
      </div>
    </section>

    <section class="container-fluid px-0 map-section">
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7465.301015775691!2d-103.393146!3d20.683793!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfd419a686a08014e!2sAirman%20Guadalajara%2C%20MODA%20CORPORATIVA!5e0!3m2!1ses!2smx!4v1621882619927!5m2!1ses!2smx" allowfullscreen="" loading="lazy"></iframe>
    </section>

    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
      <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div>
      <div v-html="modal.msg"></div>
      <div class="col-12 text-center" style="padding-top: 10px;" v-if="modal.icon == 'success'">
        <b-button class="btn btn-danger f-w-600 px-5" slot="button" v-on:click.prevent="$refs.modal.close();">Cerrar</b-button>
      </div>
    </sweet-modal>

  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        name: null,
        email: null,
        phone: null,
        msg: null
      },

      info: [],

      modal:{
        msg:'',
        icon:'',
        block:false,
      },
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center',
        appendToast: true
      })
    },

    getInfo: function(){
      axios.get(tools.url('/api/info')).then((response)=>{
        this.form = {
          name: null,
          email: null,
          phone: null,
          msg: null
        };

        this.info = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    },

    onSubmit(evt) {
      evt.preventDefault();
      //var data=tools.params(form, this.form);
      axios.post(tools.url("/api/contact"), this.form).then((response)=>{
          this.$router.push({ path: '/contacto/gracias' });
        }).catch( (error)=> {
        // console.log(error.response);
        let errors = error.response.data;
        // console.log(errors);
        this.makeToast('danger', error.response.data.name, 'Error');
        this.makeToast('danger', error.response.data.email, 'Error');
        this.makeToast('danger', error.response.data.phone, 'Error');
        this.makeToast('danger', error.response.data.msg, 'Error');
      });
    }
  },

  beforeMount(){
    this.getInfo();

    if(this.$route.query.p){
      this.form.msg = 'Me intresa el producto '+ this.$route.query.p;
    }
  },
}
</script>
