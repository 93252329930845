<template lang="html">
  <div class="col-cart-data loggedin-cart">

    <div class="col-12 pt-3 product-list-table">
      <div class="row row-products align-items-center row-history" v-if="!documentos.length">
        No existen registros en este momento.
      </div>

      <div class="row" v-for="(d, index) in documentos" :key="index" v-bind:class=" index > 0 ? 'mt-2': ''">
          <div class="col" v-if="d.tipodocumento == 'Factura'">
              <div class="card card-2">
                  <div class="card-body">
                      <div class="media">
                          <div class="media-body my-auto text-right">
                              <div class="row my-auto flex-column flex-md-row">
                                  <div class="col-auto my-auto ">
                                      <h6 class="mb-0"><router-link :to="'/usuario/pedido/'+d.id">{{ d.tipodocumento }} #{{ d.id }}</router-link></h6>
                                  </div>
                                  <div class="col my-auto "> <small>Fecha: {{ d.fecha }} </small></div>
                                  <div class="col my-auto "> <small>Estatus: {{ d.status }}</small></div>
                                  <div class="col my-auto ">
                                      <h6 class="mb-0">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(d.total) }}</h6>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  data(){
    return{
      documentos: [],
      id: null,
      estatus: ''
    }
  },

  methods: {
    getHistorial(){
      if(this.$root.user.rfc){
          axios.get(tools.url("/api/clienteDocumento/"+this.$root.user.rfc)).then((response)=>{
            this.documentos = response.data;
          }).catch((error)=>{
              console.log(error);
          });
      }
    },
  },

  mounted(){
    if(this.$root.logged){
        this.getHistorial();
    }
  }

}
</script>
