<template lang="html">
  <div id="about-us-page">

    <section class="container med-container info-section">
      <div class="row">
        <div class="col-12 col-s-title">
          <h2 class="title">NOSOTROS</h2>
        </div>

        <div class="col-md-6 col-lg-4 col-info">
          <div class="box-image">
            <img src="public/images/pages/about-us/historia.jpg">
          </div>
          <h5 class="title">Nuestra Historia</h5>

          <p>
            Somos una empresa 100% mexicana con más de tres generaciones de experiencia en el ramo textil.
          </p>
          <p class="mt-2">
            Iniciamos en 1990 comercializando playeras con diseño gráfico y arte. Luego nos dimos cuenta de la existencia de un mercado potencial de empresas que tenían la necesidad de proyectar su imagen a través de la moda corporativa.
          </p>
        </div>

        <div class="col-md-6 col-lg-4 col-info">
          <div class="box-image">
            <img src="public/images/pages/about-us/mision.jpg">
          </div>
          <h5 class="title">Nuestra Misión</h5>

          <p>
            Ser la mejor propuesta de valor en el segmento de la moda corporativa, a través de nuestros clientes, productos confiables y de alta calidad, así como un servicio diferenciado que garantice las posibilidades de hacer negocios de largo plazo en toda la cadena de valor de la empresa.
          </p>
        </div>

        <div class="col-md-6 col-lg-4 col-info">
          <div class="box-image">
            <img src="public/images/pages/about-us/vision.jpg">
          </div>
          <h5 class="title">Nuestra Visión</h5>

          <p>
            Ser la empresa líder en moda corporativa de mayor reconocimiento del país, asegurando una rentabilidad sostenida de un proveedor único y diferenciado a través de una estructura comercial profesional, innovadora y suficientemente flexible para responder ágilmente a los cambios del entorno.
          </p>
        </div>
      </div>
    </section>

    <section class="info-color-section">
      <div class="container med-container">
        <div class="row justify-content-center">
          <div class="col-12 col-s-title">
            <h2 class="title">NUESTROS VALORES</h2>
          </div>

          <div class="col-lg-11">
            <div class="row">
              <div class="col-md-6 col-lg-4 col-info">
                <h5 class="title">Innovación</h5>

                <ol>
                  <li>Proponer nuevas formas de trabajo</li>
                  <li>Iniciativa</li>
                  <li>Ser creativos y resolver</li>
                </ol>
              </div>

              <div class="col-md-6 col-lg-4 col-info">
                <h5 class="title">Trabajo en Equipo</h5>

                <ol>
                  <li>Liderazgo y responsabilidad</li>
                  <li>Cumplir con los objetivos comúnes</li>
                  <li>Actitud e iniciativa</li>
                </ol>
              </div>

              <div class="col-md-6 col-lg-4 col-info">
                <h5 class="title">Operatividad</h5>

                <ol>
                  <li>Productividad y aprovechamiento del tiempo</li>
                  <li>Cumplir con los objetivos</li>
                  <li>Ser flexibles al cambio</li>
                </ol>
              </div>

              <div class="col-md-6 col-lg-4 col-info">
                <h5 class="title">Calidad</h5>

                <ol>
                  <li>Responsabilidad sobre métricos</li>
                  <li>Cuidar el material y herramientas</li>
                  <li>Cumplir las políticas internas</li>
                </ol>
              </div>

              <div class="col-md-6 col-lg-4 col-info">
                <h5 class="title">Compromiso</h5>

                <ol>
                  <li>Responsabilidad sobre actos</li>
                  <li>Resultados/información en tiempo y forma</li>
                  <li>Servicio diferenciado</li>
                </ol>
              </div>

              <div class="col-md-6 col-lg-4 col-info">
                <h5 class="title">Liderazgo</h5>

                <ol>
                  <li>Trabajo en equipo</li>
                  <li>Dar/recibir retroalimentación</li>
                  <li>Comunicación afectiva</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container med-container info-section">
      <div class="row">
        <div class="col-12 col-s-title">
          <h2 class="title">¿POR QUÉ AIRMAN?</h2>
        </div>

        <div class="col-md-6 col-lg-4 col-why">
          <div class="box-icon">
            <img src="public/images/pages/about-us/seguridad.png" alt="Seguridad">
          </div>

          <h5 class="title">Seguridad</h5>
          <p>
            Garantizamos todos nuestros productos sobre defectos de fabricación. También diseñamos, fabricamos o adoptamos cualquier prenda sobre pedido.
          </p>
        </div>

        <div class="col-md-6 col-lg-4 col-why">
          <div class="box-icon">
            <img src="public/images/pages/about-us/confianza.png" alt="Confianza">
          </div>

          <h5 class="title">Confianza</h5>
          <p>
            Tres generaciones de experiencia en el ramo textil y una creciente cartera de prestigiosos clientes, son la garantía que puedes confiar en nosotros.
          </p>
        </div>

        <div class="col-md-6 col-lg-4 col-why">
          <div class="box-icon">
            <img src="public/images/pages/about-us/diseno.png" alt="Diseño">
          </div>

          <h5 class="title">Diseño</h5>
          <p>
            Sabemos que un uniforme de moda corporativa debe ser cómodo, durable y elegante, por eso nuestros diseños son de corte clásico confortable.
          </p>
        </div>

        <div class="col-md-6 col-lg-4 col-why">
          <div class="box-icon">
            <img src="public/images/pages/about-us/calidad.png" alt="Calidad">
          </div>

          <h5 class="title">Calidad</h5>
          <p>
            Confeccionamos las prendas impecablemente con las mejores telas disponibles, especializadas para el uso diario que además ofrecen confort, fácil tratamiento y durabilidad.
          </p>
        </div>

        <div class="col-md-6 col-lg-4 col-why">
          <div class="box-icon">
            <img src="public/images/pages/about-us/tiempo.png" alt="Tiempo">
          </div>

          <h5 class="title">Tiempo</h5>
          <p>
            Nuestros tiempos prometidos de entrega son reales, contamos con más de 500 mil prendas de inventario y gran capacidad de bordado y estampado.
          </p>
        </div>

        <div class="col-md-6 col-lg-4 col-why">
          <div class="box-icon">
            <img src="public/images/pages/about-us/variedad.png" alt="Variedad">
          </div>

          <h5 class="title">Variedad</h5>
          <p>
            Tenemos la más amplia variedad de color y tipos de tela en el mercado para cubrir todas las necesidades de tu empresa. Resurtimos desde una pieza.
          </p>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
