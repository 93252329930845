<template lang="html">
  <div class="placed-backg faqs-page" id="page-info-t1">
    <div class="bg-image"></div>

    <section class="container oversized-container content-section">
      <div class="row align-items-center justify-content-center" v-bind:class="{ 'tabla-page' : $route.params.table == 'tabla' }">
        <!-- <div class="col-lg-6 col-image">
        </div> -->

        <div class="col-lg-10 col-info" id="faqs-box">
          <h1 class="mb-3 page-title">Preguntas frecuentes</h1>
          <!-- <div v-html="content"></div> -->

          <p class="text-center">
            <a class="trans-250 btn-pdf" target="_blank" href="https://airman.com.mx/pdf/Preguntas-Frecuentes.pdf">
              Descargar PDF <i class="fas ml-2 fa-file-pdf"></i>
            </a>
          </p>
        </div>

        <div class="col-lg-10 mt-3 col-info" id="table-box">
          <hr />
          <h2 class="mb-2 page-title">Consulta nuestras tablas de medidas</h2>
          <p class="text-center">
            ¡Aquí podrás revisar las medidas para dama y caballero de todos nuestros productos!
          </p>

          <div class="row mt-4 justify-content-center">
            <div class="col-lg col-table">
              <a class="trans-250 box-table" target="_blank" href="public/files/Tablas-medidas-dama.pdf">
                <div class="box-top">
                  <i class="fas fa-female icon"></i>
                </div>

                <div class="box-bottom">
                  <h4>Tabla de Medidas<br />Dama</h4>
                </div>
              </a>
            </div>

            <div class="col-lg col-table">
              <a class="trans-250 box-table"  target="_blank" href="public/files/Tablas-medidas-caballero.pdf">
                <div class="box-top">
                  <i class="fas fa-male icon"></i>
                </div>

                <div class="box-bottom">
                  <h4>Tabla de Medidas<br />Caballero</h4>
                </div>
              </a>
            </div>
          </div>

        </div>

      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      content: '',
    }
  },

  methods: {
    getContent: function(){
      axios.get(tools.url('/api/faq')).then((response)=>{
        this.content = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    }
  },

  beforeMount(){
    this.getContent();
  }
}
</script>
