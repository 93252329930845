<template lang="html">
  <div id="header">
    <div class="header-content">

      <!-- Payments -->
      <div class="box-buy">
        <div class="container d-flex align-items-center oversized-container">
          <div class="col col-lg-3 col-xl col-ml">
            <h5>Compra de forma segura y rápida</h5>
          </div>
          <div class="col col-lg-3 col-xl col-ssl">
            <img src="public/images/shared/ssl1.png">
            <span>A 246 bits</span>
          </div>
          <div class="col-12 col-phone">
              <i class="fas fa-phone-alt icon"></i><span>{{ info.telefono }}</span>
          </div>
          <div class="col-lg-5 col-xl-7 col-logos">
            <span>Múltiples formas de pago</span>
            <i class="fab fa-cc-mastercard icon"></i>
            <i class="fab fa-cc-visa icon"></i>
            <i class="fab fa-cc-amex icon"></i>
            <img class="img-icon" src="public/images/shared/oxxo.svg" alt="Oxxo">
          </div>
        </div>
      </div>
      <!--  -->

      <!-- Navbar -->
      <b-navbar toggleable="lg" type="light" variant="default">
        <div class="container oversized-container">

          <b-navbar-brand to="/">
            <img class="logo" src="public/images/logo.svg">
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"><i class="fas fa-bars"></i></b-navbar-toggle>

          <b-collapse id="nav-collapse" class="header-navigation" is-nav>
            <b-navbar-nav class="header-menu">
              <!-- <b-nav-item class="simple-item" to="/">Inicio</b-nav-item> -->
              <li class="nav-item d-lg-none">
                <a class="nav-link dropdown-toggle" @click="isPActive = !isPActive">Productos</a>
              </li>
              <b-nav-item class="simple-item item-bg item-dama" to="/productos/dama">Dama</b-nav-item>
              <b-nav-item class="simple-item item-bg item-caba" to="/productos/caballero">Caballero</b-nav-item>
              <b-nav-item class="simple-item item-bg item-unis" to="/productos/unisex">Unisex</b-nav-item>
              <b-nav-item-dropdown class="colors-menu" id="btn-hcolors" right>
                <template slot="button-content"><span>Colores</span></template>
                <li class="box-colors" @click="clickcolor">
                  <router-link class="color" :to="'/productos/color/'+color.id" v-bind:style="{ backgroundColor: color.hexadecimal }" v-for="(color, indexColor) in colors" :key="indexColor"></router-link>
                </li>
              </b-nav-item-dropdown>
              <b-nav-item class="simple-item d-lg-none" to="">Novedades</b-nav-item>
              <b-nav-item class="simple-item" to="/nosotros">Nosotros</b-nav-item>
              <b-nav-item class="d-lg-none" to="/preguntas-frecuentes">Preguntas frecuentes</b-nav-item>
              <b-nav-item class="d-lg-none" to="/preguntas-frecuentes/tabla">Tablas de medidas</b-nav-item>
              <b-nav-item-dropdown class="d-lg-none" right>
                <template v-slot:button-content>
                  <span>Catálogos</span>
                </template>
                <!-- <b-dropdown-item to="/productos">Opción 1</b-dropdown-item> -->
                <li  v-for="(catalogo, pcatIndex) in catalogos" :key="pcatIndex">
                    <a class="dropdown-item" href="#" @click="showModal(catalogo.file)">{{ catalogo.name }}</a>
                </li>
              </b-nav-item-dropdown>
              <b-nav-item class="d-lg-none" to="/distribuidores">Distribuidores</b-nav-item>
              <b-nav-item to="/blog">Blog</b-nav-item>
              <b-nav-item class="d-lg-none simple-item" to="/contacto"><strong>Contacto</strong></b-nav-item>

              <li class="nav-item simple-item d-lg-none">
                <div class="nav-link">
                  <hr class="my-0" />
                </div>
              </li>
              <b-nav-item class="simple-item cart-item d-lg-none" to="/cart"><i class="far fa-shopping-cart"></i> Mi carrito <i class="num">{{ $root.cartCount }}</i></b-nav-item>
              <b-nav-item class="simple-item d-lg-none" to="/usuario" v-if="$root.logged"><i class="far fa-user user-icon"></i>&nbsp; Mi Cuenta</b-nav-item>
              <b-nav-item class="simple-item d-lg-none" to="/login" v-if="!$root.logged"><i class="far fa-user user-icon"></i>&nbsp; Iniciar sesión</b-nav-item>
              <b-nav-item class="simple-item d-lg-none" to="/registrarse" v-if="!$root.logged"><i class="fas fa-user-plus"></i> Registrarse</b-nav-item>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <b-nav-form class="search-form order-1 order-lg-0" @submit.prevent="searchForm">
                <b-form-input size="sm" class="mr-sm-2" placeholder="Buscar" v-model="search.keywords"></b-form-input>
                <b-button class="btn-search" type="submit"></b-button>
              </b-nav-form>

              <b-nav-item-dropdown class="extra-menu order-0 order-lg-1 d-none d-lg-flex" right>
                <div class="d-inline-block" slot="button-content"><i class="far fa-user dropdown-icon"></i></div>
                <b-dropdown-item to="/usuario" v-if="$root.logged">Mi Cuenta</b-dropdown-item>
                <b-dropdown-item to="/registrarse" v-if="!$root.logged">Registrarse</b-dropdown-item>
                <b-dropdown-item to="/login" v-if="!$root.logged">Iniciar sesión</b-dropdown-item>
              </b-nav-item-dropdown>

              <!-- <b-nav-item class="cart-item d-none d-lg-flex" to="/contacto"><i class="far fa-envelope-open-text contact-icon"></i></b-nav-item> -->
              <b-nav-item class="cart-item d-none d-lg-flex" to="/cart"><i class="far fa-shopping-cart cart-icon"></i><i class="num">{{ $root.cartCount }}</i></b-nav-item>
            </b-navbar-nav>
          </b-collapse>

        </div>
      </b-navbar>
      <!--  -->

      <!-- Header bottom -->
      <div class="header-bottom">
        <b-navbar toggleable="lg" type="dark" variant="info">
          <div class="container oversized-container px-1">
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav>
                <li class="nav-item b-nav-dropdown dropdown">
                  <a class="nav-link dropdown-toggle" @click="isPActive = !isPActive">Productos</a>
                </li>

                <b-nav-item to="/productos">Novedades</b-nav-item>
                <b-nav-item to="/preguntas-frecuentes/tabla">Tablas de medidas</b-nav-item>
                <b-nav-item to="/preguntas-frecuentes">Preguntas frecuentes</b-nav-item>

                <b-nav-item-dropdown right>
                  <template v-slot:button-content>
                    <span>Catálogos</span>
                  </template>
                  <!-- <b-dropdown-item to="/productos">Opción 1</b-dropdown-item> -->
                  <li  v-for="(catalogo, pcatIndex) in catalogos" :key="pcatIndex">
                    <!-- <a class="dropdown-item" target="_blank" :href="catalogo.file">{{ catalogo.name }}</a> -->
                    <a class="dropdown-item" href="#" @click="showModal(catalogo.file)">{{ catalogo.name }}</a>
                  </li>

                </b-nav-item-dropdown>

                <b-nav-item to="/distribuidores"><i class="fas fa-tshirt"></i> Distribuidores</b-nav-item>
                <b-nav-item class="nav-contacto" to="/contacto"><i class="fas fa-envelope"></i> Contacto</b-nav-item>
              </b-navbar-nav>
            </b-collapse>
          </div>
        </b-navbar>
      </div>
      <!--  -->

      <!-- Products menu -->
      <div class="trans-250 products-menu" v-bind:class="{ active: isPActive }">
        <div class="menu-bg" @click="isPActive = false"></div>

        <div class="tablist" role="tablist">
          <!-- <div class="trans-250 box-title" @click="isPActive = false">
            <h4>PRODUCTOS</h4>
            <br>
            <h5 v-on:click="$router.push('/productos');">Categorias</h5>
            <i class="fas fa-chevron-left icon"></i>
          </div> -->

          <b-card no-body class="mb-1" v-for="(category, pcatIndex) in categories" :key="pcatIndex">
            <b-card-header header-tag="header" role="tab">
              <a v-if="category.subcategories" class="btn-toggle" v-b-toggle="'accordion-'+pcatIndex">{{ category.name }}</a>
              <router-link v-else class="btn-toggle" :to="'/categoria/'+$root._converToURL(category.name, category.id)">{{ category.name }}</router-link>
            </b-card-header>

            <b-collapse v-if="category.subcategories" :id="'accordion-'+pcatIndex" visible accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <ul class="ul-sub-options">
                  <li v-for="subcategory in category.subcategories">
                    <router-link class="trans-250" :to="'/categoria/'+$root._converToURL(subcategory.name, subcategory.id)">{{ subcategory.name }}</router-link>
                  </li>
                </ul>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
      <!--  -->

        <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
                <div class="col-12 text-center">
                    <b-form @submit="onSubmit">
                        <b-form-group>
                            <b-form-input v-model="form.name" type="text" required placeholder="Nombre"></b-form-input>
                        </b-form-group>

                        <b-form-group>
                            <b-form-input v-model="form.email" type="email" required placeholder="Correo electrónico"></b-form-input>
                        </b-form-group>

                        <b-form-group>
                            <b-form-input v-model="form.phone" type="text" required placeholder="Teléfono" maxlength="10"></b-form-input>
                        </b-form-group>

                        <p class="text-center">
                            <b-button type="submit" class="btn-send">Descargar catálogo</b-button>
                        </p>
                    </b-form>
                </div>
        </sweet-modal>

    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
        isPActive: false,
        isDActive: false,

        categories:[],
        catalogos: [],
        colors: [],

        search: {
            keywords: null
        },

        form: {
            name: null,
            email: null,
            phone: null,
            url: null,
        },

        modal: {
            msg:'',
            icon:'',
            block:false,
        },

        info: {
            telefono: '',
            email: '',
            facebook: '',
            instagram: '',
            youtube: '',
            address: ''
        }
    }
  },

  methods:{

    showModal(url){
        this.form.url = url;
        this.$refs.modal.open();
    },

    onSubmit(evt) {
      evt.preventDefault();
      //var data=tools.params(form, this.form);
      axios.post(tools.url("/api/downloadPdf"), this.form).then((response)=>{
          window.open(this.form.url, "_blank");

          this.form = {
            name: null,
            email: null,
            phone: null,
            url:null
          };

          this.$refs.modal.close();
          this.$router.push({ path: '/gracias' });

        }).catch( (error)=> {
        console.log(error.response);
      });
    },

    clickcolor(){
      document.getElementById('btn-hcolors__BV_toggle_').click();
    },

    searchForm(evt){
        this.$router.push({path: '/busqueda', query: {keywords:this.search.keywords}});
    },

    getCategories: function(){
      axios.get(tools.url('/api/categories')).then((response)=>{
        this.categories = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    },

    getColors: function(){
      axios.get(tools.url('/api/colors')).then((response)=>{
        this.colors = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    },

    getCatalogos: function(){
      axios.get(tools.url('/api/catalogos')).then((response)=>{
        this.catalogos = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    },

    getInfo: function(){
      axios.get(tools.url('/api/info')).then((response)=>{
        this.info = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    }
  },

  watch: {
    $route (to, from){
      // Ocultar menu cada vez que cambie la ruta
      this.isPActive = false;
      this.isDActive = false;
    }
  },

  beforeMount(){
    this.getCategories();
    this.getCatalogos();
    this.getColors();
    this.getInfo();
  }
}
</script>
